






import { defineComponent } from '@vue/composition-api'
import Cookies from 'js-cookie'
import AuthenticationService from '@/services/Authentication/AuthenticationService'

export default defineComponent({
  name: 'Logout',

  async mounted() {
    Cookies.remove(AuthenticationService.userTokenCookieName, {
      sameSite: 'None',
      secure: true
    })
    await (this as any).$auth.logout({ returnTo: process.env.VUE_APP_OCTOLITE_URL })
    window.close()
  }
})
